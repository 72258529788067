.Map-frame {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.Map-frame iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 10+ */
  filter: gray; /* IE6-9 */
  -webkit-filter: grayscale(99%); /* Chrome 19+ & Safari 6+ */
  -webkit-backface-visibility: hidden;  /* Fix for transition flickering */
}