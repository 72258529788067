.CoverHero {
  width: 100%;
  height: auto;
  background-color: #333;
  background-size: cover;
}

a.navbar-item {
  color: white;
}

a.navbar-item img {
  max-height: none;
}

a.navbar-item.is-active:not(:hover), a.navbar-item:hover {
  color: white;
  background-color: #5a5a5a;
}

.CoverHero-body h1, .CoverHero-body h2 {
  color: white;
}
